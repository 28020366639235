.news-letter {
  h4 {
    color: #fff;
    margin-top: 15px !important;
    margin-bottom: 0 !important;
  }

  small {
    color: gray;
    display: inline-block;
    margin-top: 5px !important;
    margin-bottom: 12px !important;
  }
}

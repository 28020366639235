//
// Rca-Datatable scss settings
//
// Override DataTable button per button, must inside rca-data-table scope
.data-table {
  button {
    background-color: pink;
  }
}

// Override DataTable button per button, must inside rca-data-table scope
.rca-data-table {
  .btn-data-table-custom {
    background-color: yellowgreen;
  }
}

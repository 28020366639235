@import "./../../_variables.scss";

.rca-splash {
  .app-splash-logo {
    height: 20vmin;
    pointer-events: none;
  }

  .app-splash-container {
    background-color: #2f3542;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
}

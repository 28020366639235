@import "./../../_variables.scss";

.rca-news-letter {
    h4 {
      color: $news-letter-heading-color;
      margin-top: 15px !important;
      margin-bottom: 0 !important;
    }
  
    small {
      color: $news-letter-description-color;
      display: inline-block;
      margin-top: 5px !important;
      margin-bottom: 12px !important;
    }
  }
  
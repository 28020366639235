.footer-layout {
  .item {
    margin-right: 8rem;
  }

  .title {
    color: #fff;
  }

  .about-us {
    color: #d7d7d1;
  }

  // Social links color and hover
  .social-links {
    .twitter {
      color: #fff !important;
      &:hover {
        color: #1da1f2 !important;
      }
    }

    .fb {
      color: #fff !important;
      &:hover {
        color: #4267b2 !important;
      }
    }

    .git {
      color: #fff !important;
      &:hover {
        color: #d7d7d1 !important;
      }
    }

    .ig {
      color: #fff !important;
      &:hover {
        color: #c13584 !important;
      }
    }
  }
}

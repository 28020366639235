@import "./../../_variables.scss";
@import "./../../mixins/core-mixin.scss";
//@use "sass:map";

$theme-colors: (
  "success": #28a745,
  "info": #17a2b8,
  "warning": yellow,
);

.bg-color {
  background-color: $primary-color;
  color: $secondary-color;
}

%message-box-base {
  border: 1px solid #fff;
  padding: 20px;
  color: #78e08f;
  //color: map.get($theme-colors, "warning");
}

.box {
  @include transform(rotate(30deg));
}

.contact-box {
  @extend %message-box-base;
}

.contact-box2 {
  @extend %message-box-base;
  border-color: greenyellow;
  border-radius: 15px;
}

article[role="main"] {
  float: left;
  width: 660px / 960px * 100%;
}

aside[role="complementary"] {
  float: right;
  width: 300px / 960px * 100%;
}

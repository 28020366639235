@import "./../../_variables.scss";

footer {
  background-color: $footer-bgcolor !important;
  // position: absolute;
  // bottom: 0;
  width: 100%;

  .footer-content {
    p {
      text-transform: capitalize;
      margin: 0;
      padding: 15px 0;
      color: $tertiary-color;
      font-size: 0.8rem;

      a {
        color: $tertiary-color;
      }
    }
  }

  .fat-footer {
    background-color: $fat-footer-bgcolor;
    min-height: $fat-footer-height;
    padding: 2rem;
  
    @if $fat-footer-bg-enable == true {
      background-image: $fat-footer-image;
      background-repeat: repeat;
    }

    div {
      max-width: $fat-footer-content-width;
     
      a {
        font-size: 0.9rem;
        color: $fat-footer-link-color;
      }
    }
  }
}

// Layout debugger

.bg-red {
  background-color: red !important;
}

.bg-green {
  background-color: greenyellow !important;
}

.bg-blue {
  background-color: blue !important;
}

.bg-yellow {
  background-color: yellow !important;
}

.bg-orange {
  background-color: orange !important;
}

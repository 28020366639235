@import "./../../_variables.scss";

// ----------------------
// Rcnet App Typography
// ----------------------

@mixin heading-base($size, $color: #000, $letterSpacing: 2px) {
  font-family: $heading-font !important;
  font-weight: bold;
  font-size: $size;
  color: $color;
  letter-spacing: $letterSpacing !important;
}

.rca-heading1 {
  @include heading-base(80px);
}

.rca-heading2 {
  @include heading-base(60px);
}

.rca-heading3 {
  @include heading-base(50px);
}

.rca-heading4 {
  @include heading-base(40px);
}

.rca-heading5 {
  @include heading-base(30px);
}

.tsmall {
  font-size: 0.7rem;
}

.tsmall1 {
  font-size: 0.8rem;
}

.tsmall2 {
  font-size: 0.9rem;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

// ------------------------------
// Overrides Bootstrap defaults
// ------------------------------
pre,
code,
kbd,
samp {
  font-family: $regular-font !important;
}

input,
button,
select,
optgroup,
textarea {
  font-family: $regular-font !important;
}

.tooltip {
  font-family: $regular-font !important;
}

.popover {
  font-family: $regular-font !important;
}

.text-monospace {
  font-family: monospace;
}

p {
  font-family: $regular-font !important;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-family: $regular-font;;
//   letter-spacing: $heading-letterSpacing;
// }

@import "../../../assets/styles/_variables.scss";
//
// Data table styles
//
.rca-table {
  $table-border-color: #e5e5e5;
  $table-header-text-color: #666666;
  $table-header-text-bold: 700;
  $table-column-header-color: $primary-color;
  $step: 0;
  $table-sort-indicator-color: #666666;
  $row-striped-color: #f2f2f2;
  $row-hover-color: gainsboro;

  //padding: 1rem;
  display: block;

  .search-box {
    margin-bottom: 10px;
    text-align: "left";
  }

  .table {
    border-spacing: 0;
    border: 1px solid $table-border-color;
    border-radius: 5px !important;
    margin-bottom: 2px;
    width: 100% !important;

    .table-container {
      width: 100%;
      overflow-x: auto !important; // Temp!

      .table-items {
        min-width: -webkit-min-content;

        .thead {
          overflow-y: auto;
        }

        .tbody {
          overflow-x: hidden;

          // For scroll/contain mode
          &.isContain {
            overflow-y: scroll;
            height: 450px;
          }

          // For overflow mode
          &.isOverflow {
            overflow-y: hidden;
            min-height: 450px;
          }
        }

        .tb .tr {
          :last-child {
            .td {
              border-bottom: 0;
            }
          }
          border-bottom: 1px solid $table-border-color;
        }

        .th,
        .td {
          margin: 0;
          padding: 0.5rem;
          border-right: 1px solid $table-border-color;
          border-bottom: 1px solid $table-border-color;
          position: relative;

          // :last-child {
          //   border-right: 0;
          // }

          .resizer {
            right: 0;
            background: transparent;
            width: 10px;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 1;
            touch-action: none;

            &.isResizing {
              background: #f7f7f7;
            }
          }
        }

        .tr {
          &.tr-header {
            box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
          }

          &.tr-item {
            &.striped:nth-child(even) {
              background-color: $row-striped-color;
            }
          }
        }

        .tr-item:hover {
          background-color: $row-hover-color !important;
        }

        .th {
          &.th-item {
            background-color: $table-column-header-color;

            &.sortable {
              cursor: pointer;
            }

            &.sortAsc {
              border-top: 2px solid $table-sort-indicator-color;
            }

            &.sortDesc {
              border-bottom: 2px solid $table-sort-indicator-color;
            }
          }
        }
      }
    }

    .header-text {
      color: $table-header-text-color;
      //text-overflow: ellipsis;

      &.isBold {
        font-weight: $table-header-text-bold;
      }
    }
  }

  .pagination {
    .w30 {
      width: 30%;
    }

    .w40 {
      width: 40%;
    }

    .w50 {
      width: 50%;
    }

    .h10 {
      height: 10%;
    }
  }
}

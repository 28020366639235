@import "./../../mixins/core-mixin.scss";

/*
  Button definitions
*/

@include button-base("success", #fff, #78e08f, #6ed284);

@include button-base("info", #fff, #82ccdd, #76bece);

@include button-base("danger", #fff, #e55039, #d0452f);

@include button-base("warn", #fff, #f6b93b, #e4ab35);

@include button-base(
  "primary",
  #fff,
  $primary-button-color,
  $primary-button-hover-color
);

@include button-base(
  "secondary",
  #fff,
  $secondary-button-color,
  $secondary-button-hover-color
);

@include button-base(
  "tertiary",
  #000,
  $tertiary-button-color,
  $tertiary-button-hover-color
);

// Outline design
@include button-base-outline(
  "outline-primary",
  #fff,
  $primary-button-color,
  $primary-button-hover-color
);

@include button-base-outline(
  "outline-secondary",
  #fff,
  $secondary-button-color,
  $secondary-button-hover-color
);

@include button-base-outline(
  "outline-tertiary",
  #000,
  $tertiary-button-color,
  $tertiary-button-hover-color
);

@include button-base-outline(
  "outline-black",
  #fff,
  #000,
  $tertiary-button-hover-color
);

.btn-disabled {
  @if $classicDisableButton == false {
    opacity: 0.4 !important;
    pointer-events: none;
  } @else {
    background-color: rgb(168, 168, 168) !important;
    color: rgb(214, 214, 214) !important;
    border-color: rgb(168, 168, 168) !important;
    pointer-events: none;
  }
}

.in-progress-disabled {
  pointer-events: none;
}

.readonlyView {
  .fields-img-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .fields-img,
  .fields-img-signature {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    //width: 150px;
    max-height: 150px !important;
    padding: 4px;
    box-sizing: border-box;
  }

  .fields-img-signature {
    width: 250px;
    height: auto;
  }

  .file-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .fileDetails {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .file-thumb-img {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: auto;
    padding: 4px;
    box-sizing: border-box;
  }

  #multiSelect ul {
      margin-bottom: 7px;
  }

  #multiSelect li {
    margin-left: -8px;
    margin-bottom: 0;
  }

  .pdf {
    color: #df2745 !important;
  }

  .word {
    color: #00a8ec !important;
  }

  .excel {
    color: #127f46 !important;
  }

  .file {
    color: #a7a7a7 !important;
  }

  .image {
    color: #9981bc !important;
  }
}

@import "./../../_variables.scss";

.rca-nav-bar {
  // Note: need to support theming of Dark or Light
  .bg-light {
    background-color: $navbar-bg-color !important;
  }

  .bg-dark {
    background-color: $navbar-bg-color !important;
  }

  .navbar-container {
    max-width: $content-width;
  }

  .navbar-light .navbar-brand {
    color: $navbar-brand-text-color;

    &:hover {
      color: $navbar-brand-text-hover-color;
    }
  }

  .navbar-brand {
    margin-left: -5px;
  }

  .navbar-light .navbar-nav .nav-link {
    color: $navbar-text-color;
    text-transform: $navbar-text-transform;
    padding: 10px 10px 10px 10px;
    margin-right: 10px;
    font-weight: bold;

    &:hover {
      text-transform: $navbar-text-transform;
      // Check mixins for other menu style
      // Solid highlight
      @if $navbar-menu == 1 {
        @include navbar-highlight(
          $navbar-text-hover-color,
          $navbar-highlight-hover
        );
      }

      // With Line highlight
      @if $navbar-menu == 2 {
        @include navbar-highlight-underline(#fff, $navbar-highlight-hover);
      }
    }

    &:focus {
      // Check mixins for other menu style
      // Solid highlight
      @if $navbar-menu == 1 {
        @include navbar-highlight-focus(
          $navbar-text-hover-color,
          $navbar-highlight-hover
        );
      }

      // With Line highlight
      @if $navbar-menu == 2 {
        @include navbar-highlight-underline-focus(
          #fff,
          $navbar-highlight-hover
        );
      }
    }
  }

  // Highlight menu
  .navbar-active {
    @if $navbar-menu == 1 {
      color: $navbar-text-hover-color !important;
    }

    @if $navbar-menu == 1 {
      @include navbar-highlight-focus(
        $navbar-text-hover-color,
        $navbar-highlight-hover
      );
    }

    @if $navbar-menu == 2 {
      @include navbar-highlight-underline-active(#fff, $navbar-highlight-hover);
    }
  }

  .navbar-light .navbar-text {
    color: $navbar-text-color;
  }

  .navbar-text {
    display: inline-flex;
    a {
      color: $navbar-text-color !important;

      &:hover {
        color: $navbar-text-hover-color !important;
      }
    }
  }

  .navbar-brand-logo {
    max-height: 50px;
  }

  .navbar-toggler {
    background-color: $navbar-toggler-color !important;
  }

  .dropdown-item {
    &:hover {
      background-color: $navbar-highlight-hover;
      transition: 0.8s;
    }
    &:focus {
      color: $navbar-text-hover-color;
      background-color: $navbar-highlight-hover;
    }
  }
}

#mobileLayout {
  $table-border-color: #e5e5e5;
  $row-striped-color: #f2f2f2;
  $table-header-text-color: #666666;

  .item-container {
    border: 1px solid $table-border-color;
    border-radius: 5px !important;
    margin: 8px 0;

    &.striped:nth-child(odd) {
      background-color: $row-striped-color;
    }
  }

  .item {
    //margin: 5px 0;
    padding: 8px;

    .header-text {
      color: $table-header-text-color !important ;
    }
  }
}

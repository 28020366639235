// ----------
// Fonts
// ----------
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap");
$font-stack: "Open Sans", "Oswald";
$heading-font: "Oswald";
$regular-font: "Open Sans";

// --------
// Fonts
// --------
$heading-letterSpacing: 0; //1px;
$base-font-size: 16px;

// --------
// Colors
// --------
// Theme https://flatuicolors.com/palette/fr
$primary-color: #82ccdd; // spray
$secondary-color: #f6b93b; // squash blossom
$tertiary-color: #d7d7d1;

$page-bgcolor: #d7d8d1;
$content-bgcolor: #fff;
$footer-bgcolor: #2f3542;
$splash-bgcolor: #2f3542;

$navbar-menu: 1; // 1 = solid highlight, 2 = line highlight
$navbar-bg-color: rgba(0, 0, 0, 1);
$navbar-brand-text-color: #fff;
$navbar-text-color: $tertiary-color;
$navbar-text-hover-color: #000;
$navbar-brand-text-hover-color: #82ccdd;
$navbar-toggler-color: #d7d8d1;
$navbar-highlight-hover: #97dfef; //#82ccdd;
$navbar-text-transform: uppercase;

$primary-button-color: $primary-color;
$primary-button-hover-color: #76c1d2;
$secondary-button-color: $secondary-color;
$secondary-button-hover-color: #e9af36;
$tertiary-button-color: $tertiary-color;
$tertiary-button-hover-color: #cecec8;

// ---------
// Content
// ---------
$content-width: 1440px;
$enableTransitionGroupFx: true;
$content-wrapper-top-padding: 80px;

// -----------
// Fat Footer
// -----------
$fat-footer-bg-enable: false;
$fat-footer-content-width: $content-width;
$fat-footer-height: 300px;
$fat-footer-bgcolor: #000; //#0a3d62;
$fat-footer-link-color: #d7d7d1;
$fat-footer-image: url(/images/bg/pattern.jpg);

// ---------
// Buttons
// ---------
// Set to TRUE to use transparent disabled button
$classicDisableButton: false; 

// --------------
// Scroll to Top
// --------------
$scroll-to-top-color: $primary-color;

// --------------
// News Letter
// --------------
$news-letter-heading-color: #fff;
$news-letter-description-color: $primary-color;

// -------------
// DataTable
// -------------
$data-table-button-bg-color: tertiary-button-color;
$data-table-button-enable-shadow: true;
$data-table-button-transition: 0.4s;
$data-table-button-hover-color: #000;//$tertiary-button-hover-color;
$data-table-button-border-color: gray;

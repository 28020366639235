.rc-scroll-to-top {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  animation: rcScrollToTopFadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}

@keyframes rcScrollToTopFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@import "../../../../assets/styles/_variables.scss";

.short-header1-container {
  // apply to first H2 descendant
  div + h2 {
    position: absolute !important;
    top: 30px;
    left: 30px;
    color: $tertiary-color;
  }

  small,
  h5 {
    position: absolute;
    top: 130px;
    left: 30px;
    color: #fff;
  }

  .line-separator {
    position: absolute;
    top: 110px;
    left: 30px;
    border-top: $primary-color solid 5px;
    width: 100px;
  }

  .override-h5 {
    position: relative !important;
    top: 0;
    left: 0;
    color: #fff !important;
  }

  .layout-center {
    h2 {
      color: $tertiary-color !important;
    }
  }
}

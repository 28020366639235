@import "assets/styles/styles.scss";

html {
  height: 100% !important;
  font-size: $base-font-size;
}

body {
  background-color: $page-bgcolor !important;
  font-family: $font-stack !important;
  font-size: $base-font-size;
}

@import "./../../assets/styles/mixins/core-mixin";

.home {
  $menu-color: #778899;
  
  .menu-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border-radius: 10px;
    border: solid 1px #778899;
    background-color: #778899;
    color: #fff;
    font-weight: 700;
    width: 300px;
    text-align: center;
    margin: 10px 15px;
    cursor: pointer;
    transition: all 0.3s ease-out;
    @include boxShadow();

    div {
      overflow: hidden;
      word-wrap: break-word;
    }
  }

  .menu-item:hover {
    background-color: lighten($menu-color, 20%);
    border-color: #fff;
    transition: all 0.5s ease-out;
  }
}

@import "./../_variables.scss";
@import "./../mixins/core-mixin.scss";

.rca-data-table {
  button {
    background-color: $data-table-button-bg-color;
    border-color: $data-table-button-border-color;

    @if $data-table-button-enable-shadow == true {
      @include boxShadow();
    }

    &:hover {
      background-color: $data-table-button-hover-color;
      border-color: $data-table-button-border-color;
      transition: $data-table-button-transition;
      @if $data-table-button-enable-shadow == true {
        @include boxShadow();
      }
    }
  }
}

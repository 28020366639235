@import "../../../../assets/styles/_variables.scss";

.contact-us-container {
  div.contact-info-container {
    margin: 30px;
    h2 {
      text-align: center;
      margin-bottom: 30px;
    }
  }

  .map-title {
    text-align: center !important;
  }

  div.map-container {
    background-color: #f0fbfe;
  }
}

@import "./../../_variables.scss";

.App {
  //text-align: center;

  // Codes for sticky footer if content is less
  height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;

  @if $enableTransitionGroupFx == true {
    @include transition-group-fx();
  } @else {
    @include without-transition-group-fx();
  }

  .rca-page {
    background-color: $page-bgcolor;
    //padding-left: 5px;
    //padding-right: 5px;
    @if $content-wrapper-top-padding > 0 {
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }

  .rca-content-wrapper {
    max-width: $content-width;
    background-color: $content-bgcolor;
  }

  .content-wrapper {
    padding-top: $content-wrapper-top-padding !important;
  }
}

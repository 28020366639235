/**
* Core mixins
*/

// --------------------
// Transform function
// --------------------
@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

// --------------------------
// Reset padding and margin
// --------------------------
@mixin reset-padding-margin() {
  padding: 0;
  margin: 0;
}

@mixin square($size, $radius: 0) {
  width: $size;
  height: $size;

  @if $radius != 0 {
    border-radius: $radius;
  }
}

// ---------
// Button
// ---------
@mixin button-base(
  $name,
  $color,
  $bgColor,
  $hoverColor,
  $hoverColorText: $hoverColor,
  $borderRadius: 5,
  $borderColor: $bgColor,
  $isBold: false,
  $isUpperCase: false,
  $transition: 0.4s
) {
  button.rca-btn-#{$name} {
    color: $color;

    @if $isBold == true {
      font-weight: 600;
    }

    @if $isUpperCase == true {
      text-transform: capitalize;
    }
    background-color: $bgColor;
    border-radius: $borderRadius;
    border-color: $borderColor;
    @include boxShadow();

    &:hover {
      background-color: $hoverColor;
      border-color: $borderColor;
      transition: $transition;
      @include boxShadow(2);
    }

    &:focus {
      background-color: $hoverColor !important;
      border-color: $borderColor;
      transition: $transition;
      @include boxShadow(2);
    }

    &:active {
      background-color: $hoverColor !important;
      border-color: #000;
      transition: $transition;
      @include boxShadow(2);
    }
  }
}

@mixin button-base-outline(
  $name,
  $color,
  $bgColor,
  $hoverColor,
  $hoverColorText: $hoverColor,
  $borderRadius: 5,
  $borderColor: #fff,
  $isBold: false,
  $isUpperCase: false,
  $transition: 0.4s
) {
  button.rca-btn-#{$name} {
    border: 2px solid $borderColor !important;
  }

  @include button-base(
    $name,
    $color,
    $bgColor,
    $hoverColor,
    $hoverColorText,
    $borderRadius,
    $borderColor,
    $isBold,
    $isUpperCase,
    $transition
  );
}

// -------------------
//  Navbar Highlight
// -------------------

// With solid highlight box
@mixin navbar-highlight(
  $color,
  $bgColor,
  $padding: 10px,
  $borderRadius: 5px,
  $fontWeight: bold,
  $transition: 0.8s
) {
  color: $color;
  background-color: $bgColor;
  font-weight: $fontWeight;
  padding: 10px;
  border-radius: 5px;
  transition: $transition;
}

@mixin navbar-highlight-focus(
  $color,
  $bgColor,
  $padding: 10px,
  $borderRadius: 5px,
  $fontWeight: bold
) {
  color: $color;
  background-color: $bgColor !important;
  font-weight: $fontWeight;
  padding: 10px;
  border-radius: 5px;
}

// With solid underline hover
@mixin navbar-highlight-underline($color, $lineColor, $padding: 10px) {
  color: $color;
  padding: $padding;
  border-bottom: solid 3px $lineColor;
}

@mixin navbar-highlight-underline-focus($color, $lineColor, $padding: 10px) {
  color: $color;
  padding: $padding;
  border-bottom: solid 3px $lineColor;
}

@mixin navbar-highlight-underline-active($color, $lineColor, $padding: 10px) {
  color: $color;
  padding: $padding;
  border-bottom: solid 3px $lineColor;
  text-transform: lowercase;
}

// ----------
// Box Shadow
// ----------
@mixin boxShadow($step: 0, $color: #bababa) {
  -webkit-box-shadow: (2px + $step) (2px + $step) 15px -1px $color;
  box-shadow: (2px + $step) (2px + $step) 15px -1px $color;
}

// --------------
// Text Shadow
// --------------
@mixin textCellShaded() {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

// -----------------------
// React Transition Group
// -----------------------
@mixin transition-group-fx(
  $fadeEnterOpacity: 0.01,
  $fadeExitOpacity: 0.01,
  $fadeEnterOpacitySpeed: 300ms,
  $fadeExitOpacitySpeed: 300ms
) {
  .fade-enter {
    opacity: $fadeEnterOpacity;
  }

  .fade-enter.fade-enter-active {
    position: absolute;
    top: 50;
    left: 0;
    opacity: 1;
    transition: opacity $fadeEnterOpacitySpeed ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: $fadeExitOpacity;
    transition: opacity $fadeExitOpacitySpeed ease-in;
  }

  div.transition-group {
    position: relative;
  }

  // Responsible for sticky footer if less content
  section.content-wrapper {
    //flex: 1 0 auto !important; // new
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
  }

  section.route-section {
    //position: relative; // this is relative to content-wrapper
    width: 100%;
  }
}

@mixin without-transition-group-fx {
  // Responsible for sticky footer if less content
  section.content-wrapper {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
  }

  section.route-section {
    position: relative; // this is relative to content-wrapper
    width: 100%;
    top: 50;
    left: 0;
  }
}
